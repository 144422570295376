<div class="pt-10">
  <strong class="block font-bold text-2xl md:text-4xl mb-6">
    {{
      subscriptionType === 'SCHOOL_CUSTOM'
        ? 'Angebot anfordern'
        : 'Bestellübersicht'
    }}
  </strong>

  <div
    class="flex flex-col gap-4 border-[#B5B6C1] border rounded-xl p-4 text-base">
    <div class="flex flex-col gap-4">
      @for (condition of getOfferConditions(); track condition.title) {
        <div class="flex flex-col md:flex-row">
          <strong class="box md:w-[220px] shrink-0">
            {{ condition.title }}
          </strong>
          <div [innerHTML]="condition.description"></div>
        </div>
      }
    </div>

    @if (subscriptionType !== 'SCHOOL_CUSTOM') {
      <mat-divider></mat-divider>
      <div class="flex flex-col md:flex-row">
        <strong class="box md:w-[220px] shrink-0 pb-2 md:pt-4"
          >Kooperationscode:</strong
        >
        <form
          class="flex flex-col gap-x-4 lg:flex-row w-full"
          [formGroup]="codeForm">
          <div class="grow">
            <mat-form-field
              class="w-full"
              [class.success]="appliedCouponType !== null">
              <mat-label>Code</mat-label>
              <input matInput formControlName="code" />
              @if (appliedCouponType !== null) {
                <mat-icon
                  class="material-symbols-rounded scale-75 font-bold"
                  matSuffix
                  >check</mat-icon
                >
              }
            </mat-form-field>
            @if (hasCodeError) {
              <mat-error>Kooperationscode ungültig.</mat-error>
            }
          </div>
          <button
            class="w-fit ml-auto mr-0 lg:mt-2"
            color="primary"
            mat-stroked-button
            [disabled]="appliedCouponType !== null"
            (click)="verifyAndAddCode()">
            <span class="px-2">Hinzufügen</span>
          </button>
        </form>
      </div>
    }
  </div>

  <div class="flex flex-col gap-4 pt-6">
    <div class="text-sm text-[#B5B6C1]">
      @if (showRegularFootNotes()) {
        <div>
          <p>
            * Du kannst dein Abonnement innerhalb der ersten 3 Monate jederzeit
            per Mail künden. Somit entstehen keine Kosten.
          </p>
          <p>
            ** Ohne Kündigung tritt nach Ablauf der ersten 3 Monate dein
            Bildigsnetz-Jahresabo in Kraft. Die Abonnementskosten werden
            jährlich in Rechnung gestellt. Die erste Rechnung wird nach Ablauf
            der ersten 3 Monate gestellt.
            @if (appliedCouponType === 'VSLCH') {
              Als VSLCH-Mitglied profitiert deine Schule nach dem ersten Jahr
              von einem 15%-Dauerrabatt.
            }
          </p>
        </div>
      } @else {
        <div>
          <p>
            * Ohne Kündigung verlängert sich das Abo (siehe AGB) jeweils um ein
            Jahr
          </p>
        </div>
      }
    </div>

    <mat-divider></mat-divider>

    <div class="text-base">
      <div class="flex flex-row justify-between">
        <strong>{{
          subscriptionType === 'SCHOOL_CUSTOM'
            ? 'Kontaktdaten'
            : 'Rechnungsadresse'
        }}</strong>
        <mat-icon
          class="material-symbols-rounded scale-100 cursor-pointer"
          (click)="onPrev()"
          >edit</mat-icon
        >
      </div>
      <p class="mb-0">
        {{ addressData?.organization }}<br />
        {{ addressData?.name }}<br />
        {{ addressData?.street }} {{ addressData?.streetNumber }}<br />
        {{ addressData?.zipcode }} {{ addressData?.city }}
      </p>
    </div>

    <mat-divider></mat-divider>

    <div class="text-base">
      <div class="flex flex-row justify-between">
        <strong>E-Mail</strong>
        <mat-icon
          class="material-symbols-rounded scale-100 cursor-pointer"
          (click)="onPrev()"
          >edit</mat-icon
        >
      </div>
      <div>
        {{ addressData?.email }}
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>

  <form [formGroup]="form" class="pt-4">
    @if (subscriptionType !== 'SCHOOL_CUSTOM') {
      <mat-checkbox color="primary" formControlName="acceptTos">
        <span class="text-base text-[#B5B6C1]">
          Hiermit bestätige ich, dass ich die
          <a
            target="_blank"
            href="https://bildigsnetz.ch/agb"
            class="hover:underline primary-color"
            >AGB</a
          >
          gelesen, verstanden und akzeptiert habe.
        </span>
      </mat-checkbox>
    }

    <app-action-button
      class="block mt-8"
      (onClick)="onSubmit()"
      [disabled]="form.invalid"
      text="{{
        subscriptionType === 'SCHOOL_CUSTOM'
          ? 'Angebot anfordern'
          : 'Abo abschliessen'
      }}"></app-action-button>
  </form>
</div>
